<template>
  <div class="report">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">举报</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="reportTitle">
        详情描述
        <span>（必填）</span>
      </div>
      <van-field
        class="reportInput"
        :border="false"
        v-model="reportContent"
        rows="6"
        autosize
        type="textarea"
        maxlength="100"
        placeholder="请详细描述遇到的问题，以使我们及时为您解决问题"
        show-word-limit
      />
      <div class="addPicture">添加图片</div>
      <UploaderFile class="upload" ref="uploadImg" @uploadSuccess="uploadSuccess" @delImg="delImg" :multiple="true" maxCount="6" />

      <div class="submit" @click="report">立即提交</div>
    </div>
  </div>
</template>

<script>
import { Field, Toast } from 'vant';
import { report } from '@/api/user';
import UploaderFile from '@/components/UploaderFile';
export default {
  name: 'Report',
  components: {
    UploaderFile,
    [Field.name]: Field,
  },
  data() {
    return {
      reportContent: '',
      imgList: [],
    };
  },
  created() {},
  methods: {
    async report() {
      if (!this.reportContent) return Toast('请填写详情描述');
      let req = {
        uid: this.$store.getters.userInfo.uid,
        objType: 'user',
        types: '其他',
        objUID: this.$route.query.uid ? Number(this.$route.query.uid) : undefined,
        imgs: this.imgList,
        content: this.reportContent,
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(report, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code == 200) {
          this.imgList = [];
          this.reportContent = '';
          this.$refs.uploadImg.clearImg();
          Toast('举报成功');
        } else {
          Toast(res.tip || '举报失败，请稍后再试');
        }
      } catch (error) {
        Toast('操作失败');
        this.$store.commit('app/SET_LOADING', false);
      }
    },
    // 上传成功回调
    uploadSuccess(imgUrl) {
      // console.log(imgUrl);
      this.imgList.push(imgUrl);
    },
    // 图片删除回调
    delImg(index) {
      this.imgList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.report {
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .main {
    padding: 0px 16px;
    .reportTitle {
      font-size: 16px;
      padding: 12px 0 10px;
      span {
        color: rgb(255, 103, 143);
      }
    }
    .reportInput {
      background: #fff;
      border-radius: 4px;
      // color: #fff;
      /deep/ .van-field__word-limit {
        color: #5d6472;
      }

      /deep/ .van-field__control {
        color: rgb(102, 102, 102);
        font-size: 14px;
      }

      ::placeholder {
        color: #939bac;
        font-size: 14px;
      }
    }
    .addPicture {
      font-size: 16px;
      padding: 18px 0 11px;
    }

    .submit {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      height: 42px;
      width: 304px;
      bottom: 78px;
      color: #fff;
      line-height: 42px;
      border-radius: 4px;
      background-color: rgb(255, 103, 143);
    }
  }
}
</style>
